import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';

const LazyImage = ({ src, alt, component, styleish, imgOnly, ...props }) => {
    const [isVisible, setIsVisible] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(wrapperRef.current); // Unobserve only when visible
                }
            },
            { threshold: 0.5 } // Adjust threshold as needed
        );

        if (wrapperRef.current) {
            observer.observe(wrapperRef.current);
        }

        // Clean up observer on unmount
        return () => {
            if (wrapperRef.current) {
                observer.unobserve(wrapperRef.current);
            }
        };
    }, []);

    const renderContent = useMemo(() => {
        if (isVisible) {
            // Display the component if `imgOnly` is false, otherwise display the image
            return imgOnly ? (
                <img src={src} alt={alt} className={props.className} style={{ ...styleish }} />
            ) : (
                <div style={{ ...styleish }}>{component}</div>
            );
        }

        // Placeholder while loading
        return <span style={{ minHeight: '200px', ...styleish }}>Loading...</span>;
    }, [alt, component, imgOnly, isVisible, props.className, src, styleish]);

    return (
        <div ref={wrapperRef} style={{ height: '100%' }}>
            {renderContent}
        </div>
    );
};

export default LazyImage;