import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import "../sass/layout/_contactUs.scss";
import "../sass/base/_base.scss";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import { Banner } from "../components/banner.js";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import axios from "axios";
import countryCodes from "../static/data/countryCodes.js";

export const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      howDidTheyHear: "",
      countryCode: "",
    },
  });

  const [renderMoreContact, setRenderMoreContact] = React.useState(false);
  const { width } = useWindowDimensions();
  const [fontSize, setFontSize] = React.useState({
    bannerTop: "8rem",
    bannerBottom: "2rem",
  });

  useEffect(() => {
    if (window.location.href.indexOf("contact") > -1) {
      setRenderMoreContact(true);
    }
  }, []);

  const onSubmit = useCallback(async (data) => {
    if (data) {
      try {
        const response = await axios
          .post("https://wander-server.vercel.app/api/contactUs", data)
          .catch((e) => console.log("Hello ", e));
        console.log("Respnose : ", response);
      } catch (e) {
        throw new Error(e);
      }
    }
  }, []);

  useEffect(() => {
    if (width > 900 && width <= 1200) {
      setFontSize({
        bannerTop: "6rem",
        bannerBottom: "1.8rem",
      });
      return;
    } else if (width > 600 && width <= 900) {
      setFontSize({
        bannerTop: "5rem",
        bannerBottom: "1.5rem",
      });
      return;
    } else if (width <= 600) {
      setFontSize({
        bannerTop: "4rem",
        bannerBottom: "1.4rem",
      });
      return;
    }

    return setFontSize({
      bannerTop: "8rem",
      bannerBottom: "2rem",
    });
  }, [width]);

  return (
    <>
      {renderMoreContact && (
        <>
          <h1>Contact us</h1>
          <div className="cu-container-part-1">
            <div className="cu-container-part-1-map-container">
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Map
                  mapId={process.env.REACT_APP_MAPS_ID}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  defaultCenter={{ lat: 1.33381, lng: 103.80877 }}
                  defaultZoom={15}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                >
                  <AdvancedMarker
                    clickable
                    position={{ lat: 1.33381, lng: 103.80877 }}
                  >
                    <Pin />
                  </AdvancedMarker>
                </Map>
              </APIProvider>
            </div>

            <div className="cu-container-part-1-details">
              <div className="cu-container-part-1-details-upper">
                <p>
                  We're here to assist you with any inquiries or travel
                  bookings. Whether you're seeking information on our
                  extraordinary destinations, planning your dream journey, or
                  simply looking for guidance, our team is ready to help.
                </p>

                <h1>How to reach us</h1>
                <ul>
                  You can connect with us via the following options:
                  <li>
                    <b>Phone:</b> <br />
                    For immediate assistance, feel free to call us
                    at <b>91821730</b>
                  </li>
                  <li>Email: <br />
                    <b>info@wanderluxe.in</b>
                  </li>
                  <li>
                    Contact Form: <br />
                    Use the convenient <b>contact form below
                      to share your details</b> and queries. We'll get back to you as
                    soon as possible.
                  </li>
                  <li>
                    Visit Our Office: <br />
                    If you're in the area, we welcome
                    you to visit our office at{" "}
                    <b>255 Arcadia Road, Singapore 289850.</b> Our friendly
                    staff will be delighted to assist you in person.
                  </li>
                  <li>
                    Follow Us: <br />
                    Stay updated with our latest news, offers,
                    and travel inspiration by following us on <b>social media
                      platforms.</b>
                  </li>
                </ul>
              </div>
              {/* <div className="cu-container-part-1-details-sub">
                <ul style={{ textTransform: "uppercase" }}>
                  <li>Email</li>
                  <li>Call</li>
                  <li>Whatsapp</li>
                </ul>
                <ul>
                  <li>info@wanderluxe.in</li>
                  <li>+91123455678</li>
                  <li>+91123455678</li>
                </ul>
              </div> */}
            </div>
          </div>
          <Banner
            renderIcons={true}
            fontSize={fontSize.bannerTop}
            textTransform={"initial"}
            heading={"We'd Love To Hear From You"}
            backgroundColor={"#97B2B7"}
          />
        </>
      )}

      <div className="cu-container">
        {/* <h1>YOUR DETAILS</h1> */}
        <div className="cu-heading-sub-container">
          <h1 style={{ textTransform: "initial" }}>
            Let's Connect and Plan Your Next Adventure!
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="cu-form">
          <div className="cu-form-left">
            <ul>
              <div className="input-container">
                <p>Title:</p>
                <select
                  className="input-container-select"
                  {...register("title", { required: true })}
                >
                  <option className="input-container-select-option" value="Mrs">
                    Mrs
                  </option>
                  <option className="input-container-select-option" value="Mdm">
                    Mdm
                  </option>
                  <option className="input-container-select-option" value="Ms">
                    Ms
                  </option>
                  <option
                    className="input-container-select-option"
                    value="Miss Dr"
                  >
                    Miss Dr
                  </option>
                  <option
                    className="input-container-select-option"
                    value="Prof"
                  >
                    Prof
                  </option>
                </select>
              </div>
              <div className="input-container">
                <p>Fill the form to contact us.</p>
                {/* <p>First Name:</p>
                <input {...register("firstName", { required: true })} /> */}
              </div>
            </ul>
            <ul>
              <div className="input-container">
                <p>First Name:</p>
                <input {...register("firstName", { required: true })} />
              </div>
              <div className="input-container">
                <p>Last Name:</p>
                <input {...register("lastName", { required: true })} />
              </div>

            </ul>
            <ul>
              <div className="input-container">
                <p>Country Code:</p>
                <select
                  className="input-container-select"
                  {...register("countryCode", { required: true })}
                >
                  {countryCodes.map((country) => (
                    <option value={Object.keys(country)["code"]}>
                      {Object.keys(country) +
                        "+" +
                        "\t" +
                        Object.values(country)[0].code}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-container">
                <p>Phone Number:</p>
                <input {...register("phone", { required: true })} />
              </div>
            </ul>
            {/* <div className="info-row">
              <div className="info-row-element">
                <h2>
                  Email
                </h2>
                <p>info@wanderluxe.in</p>
              </div>
              <div className="info-row-element">
                <h2>
                  Call
                </h2>
                <p>+91 12456789089</p>
              </div>
              <div className="info-row-element">
                <h2>
                  Whatsapp
                </h2>
                <p>+91 12456789089</p>
              </div>
            </div> */}
          </div>

          <div className="cu-form-right">
            <div className="input-container" style={{ width: "100%" }}>
              <p>Email: </p>
              <input {...register("email")} />

              {/* <textarea {...register("howDidTheyHear", { required: true })} rows={7} onChange={e => setRequest(e.target.value)} /> */}
            </div>
            <div className="input-container" style={{ width: "100%" }}>
              <p>Your Message</p>
              <textarea {...register("message", { required: true })} rows={4} />
            </div>
          </div>
        </form>
      </div >

      {/* <div className="send-request-row" style={{ flex: 0.3 }}>
        <button onClick={handleSubmit(onSubmit)} className="button-greyish">
          Send Request
        </button>
        <p>
          By entering your email address, you agree to receiving our updates
          and travel recommendations with Wanderluxy. T&C apply.
        </p>
      </div> */}
      {renderMoreContact && (
        <Banner
          width={width > 600 ? "50%" : "100%"}
          renderIcons={false}
          fontSize={fontSize.bannerBottom}
          textTransform={"initial"}
          heading={
            "Your travel dreams are just a message or a call away. We look forward to helping you make your journey extraordinary."
          }
          backgroundColor={"#97789b"}
        />
      )
      }
    </>
  );
};
