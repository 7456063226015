import React, { useEffect, useState } from "react";
import "../sass/main.scss";
import footerButton from "../static/images/WL_symbol_pd.png";
import landing_latest from "../static/images/landing/landing_latest-min.jpg";
import banner from "../static/images/logos/WL_Logo-02.svg";
import { Banner } from "../components/banner.js";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "../components/LazyImage.js";

export default function Landing() {
  const [bannerFontSize, setBannerFontSize] = useState("3rem");
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 900 && width <= 1200) {
      setBannerFontSize("3rem");
      return;
    } else if (width > 600 && width <= 900) {
      setBannerFontSize("2rem");
      return;
    } else if (width <= 600) {
      setBannerFontSize("1.4rem");
      return;
    }

    return setBannerFontSize("2rem");
  }, [width]);

  const onClick = React.useCallback(() => {
    window.scrollTo({
      top: document.getElementById("drag-this-up").offsetTop + 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="header">
        <div className="header-part-2" style={{
          width: "100%",
          backgroundImage: `url(${landing_latest})`,
          backgroundSize: "cover",
        }}>
          <div className="header__text-box">
            <img src={banner} alt="" />
            <ul>
              <li><p>TRAVEL</p></li>
              <li><p>CONNECT</p></li>
              <li><p>DISCOVER</p></li>
            </ul>
          </div>
        </div>


        <div
          id="drag-this-up"
          className="header__footer-img-container"
          style={{ cursor: "pointer" }}
          onClick={onClick}
        >
          <img
            src={footerButton}
            alt={"Button that drags up page"} className="header__footer-img"
          />
        </div>
      </div >

      <Banner
        style={{ marginTop: 0 }}
        width={width > 600 ? "80%" : "100%"}
        fontSize={bannerFontSize}
        renderIcons={false}
        backgroundColor={"#97789B"}
        heading={
          "WE ARE A PASSIONATE TEAM OF EXPLORERS, DEVOTED TO CRAFTING UNIQUE AND UNFORGETTABLE EXPERIENCES, TAILOR-MADE FOR WOMEN TRAVELERS."
        }
      />
    </>
  );
}

