import React, { useMemo } from "react";
import wwa1 from "../static/images/aboutUs/WL-44.webp";
import mission_1 from "../static/images/aboutUs/Mission_1.webp";
import mission_2 from "../static/images/aboutUs/Mission_2.webp";
import mission_3 from "../static/images/aboutUs/Mission_3.webp";
import isha from "../static/images/aboutUs/Isha.jpeg";
import tripta from "../static/images/tripta.jpg";
import landing from "../static/images/aboutUs/landing.webp";
import aboutUsData from "../static/data/aboutUsCards.js";
import "../sass/main.scss";
import { ContactUs } from "./contactUs.js";
import LazyImage from "../components/LazyImage.js";

const missionData = [
  {
    icon: mission_1,
    heading: "Travel",
    description:
      "Curate exceptional, safe, and immersive travel experiences that showcase the beauty and diversity of global destinations.",
  },
  {
    icon: mission_2,
    heading: "Connect",
    description:
      "Foster meaningful connections between solo women travelers and local communities, promoting cultural exchange and mutual understanding.",
  },
  {
    icon: mission_3,
    heading: "Discover",
    description:
      "Facilitate personal growth and self-discovery through carefully crafted journeys that challenge, inspire, and empower our travelers.",
  },
];

export const AboutUs = () => {
  const renderValueCards = useMemo(() => {
    return aboutUsData.map((element) => (
      <div className="about-us-gif-card">
        <img className="" alt="" src={element.icon} />
        <h2 className="">{element.heading}</h2>
        <p className="">{element.description}</p>
      </div>
    ));
  }, []);

  const renderMissionData = useMemo(() => {
    return missionData.map((element) => {
      return (
        <div className="mission-column">
          <LazyImage component={
            <img
              src={element.icon}
              alt={element.heading}
              className="mission-img"
            />
          } />
          <h2 className="mission-heading">{element.heading}</h2>
          <p className="mission-description">{element.description}</p>
        </div>
      );
    });
  }, []);

  return (
    <div>
      <h1 className="about-us-heading">About Us</h1>
      <LazyImage component={<div className="header__about-us" />} />
      <div className="about-us-container">
        <div className="about-us-leftSide">
          <h1 className="about-us-leftSide-heading">Our Vision</h1>
          <p>
            At WanderLuxe, we envision a world where
            <b style={{ fontFamily: "TrebuchetBold" }}>
              {" "}
              travel transcends boundaries
            </b>
            , connections deepen understanding, and discoveries inspire personal
            growth. We strive to be the{" "}
            <b style={{ fontFamily: "TrebuchetBold" }}>
              catalyst for transformative journeys that empower women
            </b>{" "}
            to{" "}
            <b style={{ fontFamily: "TrebuchetBold" }}>
              explore the world with confidence, curiosity, and compassion.
            </b>
          </p>
          <img className="about-us-leftSide-img" src={wwa1} alt="" />
        </div>
        <div className="about-us-rightSide">
          <img className="about-us-rightSide-img" src={landing} alt="" />
          <div style={{}}>
            <h1 style={{ textTransform: "uppercase" }}>Who Are We?</h1>
            <p>
              At WanderLuxe, we're more than just a travel company. We're
              <b style={{ fontFamily: "TrebuchetBold" }}>
                {" "}
                dreamers, explorers, and believers
              </b>{" "}
              in the transformative power of travel. Our{" "}
              <b style={{ fontFamily: "TrebuchetBold" }}>
                values are the compass that guides every journey
              </b>{" "}
              we create:
            </p>
          </div>
        </div>
      </div>

      <div className="about-us-gifs-row">{renderValueCards}</div>

      <div className="founders-container">
        <h1 className="founders-container-heading">Meet Our Founders</h1>
        <div className="founders-container-banner">
          <div className="founders-container-banner-founder">
            <h3>CO - FOUNDER </h3>
            <h2>Isha Goyal</h2>
            <p>
              Isha Goyal, Executive Director & CEO of STIC Travel Group, and
              co-founder of WanderLuxe, is a visionary leader in the travel
              industry. With over 20 years of experience, Isha has honed her
              expertise in crafting luxurious and secure travel experiences.
              Based in Singapore and deeply connected to her roots in India, she
              brings a unique global perspective to her ventures. WanderLuxe was
              born out of Isha’s passion for exploration and her commitment to
              creating enriching travel experiences for women. Recognizing the
              need for safe, authentic journeys, she partnered with Tripta Singh
              to offer high-end, tailored adventures that reflect their shared
              love for travel in India and beyond. Isha’s philosophy is that
              travel should be transformative, providing not just memories but a
              sense of empowerment and joy. Through WanderLuxe, she continues to
              redefine what luxury travel means, ensuring that every woman can
              embark on a journey that is as enriching as it is unforgettable.
            </p>
          </div>
          <div className="founders-container-banner-images">
            <img src={isha} className="founders-image" alt="Isha" />
            <img className="founders-image" src={tripta} alt="Tripta" />
          </div>

          <div className="founders-container-banner-founder">
            <h3>CO - FOUNDER </h3>
            <h2>Tripta Singh</h2>
            <p>
              Tripta Singh is an avid traveller with 30 years of experience in
              the corporate world, spanning media, broadcasting, digital, and
              children’s toys. Having explored over 50 countries with family,
              friends, and her cherished women’s travel tribe, she brings with
              her a wealth of travel knowledge and passion. For the past 20
              years, Tripta has called Singapore home. Her latest venture,
              WanderLuxe, is a labour of love aimed at unveiling the hidden gems
              of India and beyond to Singaporeans and expats, offering them
              experiences like never before.
            </p>
          </div>
        </div>
      </div>

      <div className="mission-content-container">
        <h1
          className="mission-content-heading"
          style={{ textTransform: "initial" }}
        >
          Our mission at WanderLuxe is to:
        </h1>
        <div className="about-us-mission-row">
          {renderMissionData}
          <p>
            We are committed to providing a{" "}
            <b style={{ fontFamily: "TrebuchetBold" }}>
              supportive environment for women to explore the world
            </b>
            , create
            <b style={{ fontFamily: "TrebuchetBold" }}> lasting friendships</b>,
            and return home with newfound perspectives and cherished memories.
          </p>
        </div>
      </div>

      <ContactUs />
    </div>
  );
};
