import React from "react";
import ethos from "../static/data/ethos.js";
import "../sass/layout/_ourEthos.scss";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "../components/LazyImage.js";

export const OurEthos = () => {
  const navigate = useNavigate();

  const navigateToAbout = () => {
    navigate("/about");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { width } = useWindowDimensions();

  return (
    <div className="ethos-container">
      <h1>Our Ethos</h1>
      <div className="ethos-container-cards">
        {ethos.map((quality, index) => {
          return (
            <div className="ethos-column">
              <ul>
                <LazyImage
                  imgOnly={true}
                  src={quality.image}
                  alt={quality.heading}
                  className="ethos"

                />
              </ul>
              <ul className="ethos-column-details">
                <li className="wander">Wanderluxe</li>
                <li className="ethos-column-details-heading">
                  <h2>{quality.heading}</h2>
                </li>
                <li className="ethos-column-details-description">
                  <h2>{quality.description}</h2>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
      <button
        onClick={navigateToAbout}
        className="button-greyish"
        style={{ alignContent: width < 600 && "left", marginLeft: width < 600 && "2rem" }}
      >
        About us
      </button>
    </div>
  );
};
