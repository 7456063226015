import React, { useCallback, useMemo, useState } from "react";
import faqs from "../static/data/faq.json";
import "../sass/main.scss";

export const FAQ = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showItem, setShowItem] = useState(false);
  const [opacity, setOpacity] = useState(0.5);

  const renderAnswer = useCallback(
    (index) => {
      setSelectedIndex(index);
      if (selectedIndex === index) {
        setShowItem((prevState) => !prevState);
      } else setShowItem(true);
    },
    [selectedIndex]
  );

  const renderFAQs = useMemo(() => {
    return faqs.map((item, index) => {
      return (
        <div key={index}>
          <h1
            style={{ opacity: selectedIndex === index && showItem ? opacity : 0.5 }}
            onClick={() => renderAnswer(index)}
            className="faq-question-heading"
            onMouseEnter={() => {
              // setSelectedIndex(index);
              setOpacity(1);
            }}
          >
            &#8226; {item.question}
          </h1>
          <div
            className="faq-list"
            style={{ width: item.answers[1] ? "100%" : "50%" }}
          >
            {showItem && selectedIndex === index && (
              <div className="faq-list-item">
                <div
                  style={{
                    flex: item.answers[1] ? "5 5 50%" : "10 10 100%",
                    justifyContent: "space-between",
                  }}
                  className="faq-list__left"
                >
                  {item.answers[0]?.map((ans) => (
                    <p>{ans}</p>
                  ))}
                </div>
                <div
                  style={{
                    flex: item.answers[1] ? "5 5 50%" : "0",
                    justifyContent: "space-between",
                  }}
                  className="faq-list__right"
                >
                  {item.answers[1]?.map((ans) => (
                    <p>{ans}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [opacity, renderAnswer, selectedIndex, showItem]);


  return (
    <div className="faq-container">
      <h1 className="faq-big-heading">Frequently Asked Qs</h1>
      {renderFAQs}
    </div>
  );
};
