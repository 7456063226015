import React, { useCallback, useMemo } from "react";
import "../sass/layout/_testimonials.scss";
import "../sass/layout/_sheVentures.scss";
import testimonials from "../static/data/testimonials.json";
import testimonial_1 from "../static/images/testimonial_1.jpeg";
import testimonial_2 from "../static/images/testimonial_2.jpeg";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "../components/LazyImage.js";

const IMAGES = [testimonial_1, testimonial_2];

export const Testimonials = () => {
  const [rotateLeft, setRotateLeft] = React.useState(false);
  const [rotateRight, setRotateRight] = React.useState(false);
  const { width } = useWindowDimensions();
  const [currentCountryIndex, setCurrentCountryIndex] = React.useState(0);

  const switchTestimonial = useCallback(() => {
    setCurrentCountryIndex(
      (prevIndex) => (prevIndex + 1) % testimonials.length
    );
  }, []);

  const goBack = useCallback(() => {
    setCurrentCountryIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  }, []);

  const renderDetails = useMemo(() => {
    return (
      <div
        className="testimonial-details-container"
        style={{ marginTop: "1rem" }}
      >
        {/* <object data={comma} type="image/svg+xml" className="testi-comma">
                    <img src="yourfallback.jpg" alt="comma" />
                </object> */}
        <h2>{testimonials[currentCountryIndex].name}</h2>
        <h3>
          {testimonials[currentCountryIndex].trip},{" "}
          {testimonials[currentCountryIndex].tour}
        </h3>
        <p>{testimonials[currentCountryIndex].words}</p>
      </div>
    );
  }, [currentCountryIndex]);

  const toggleLeft = useCallback(
    () => setRotateLeft(!rotateLeft),
    [rotateLeft]
  );

  const toggleRight = useCallback(
    () => setRotateRight(!rotateRight),
    [rotateRight]
  );

  return (
    <>
      <h1 className="testimonials-heading">Testimonials</h1>
      <div className="testimonials-container">
        <div className="testimonial-left">
          {renderDetails}

          <div className="testimonials-arrow-container">
            <div
              className="arrow-container"
              style={{ marginLeft: width > 600 && "3rem" }}
              onMouseEnter={toggleLeft}
              onMouseLeave={toggleLeft}
              onClick={goBack}
            >
              <div
                className={rotateLeft ? "diamond rotate-back" : "diamond"}
                style={{ backgroundColor: "#97789b" }}
              >
                <div className="arrow" style={{ border: "0.9px solid white" }}>
                  <div
                    className="arrow-before"
                    style={{
                      borderTop: "1px solid white",
                      borderRight: "1px solid white",
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div
              className="arrow-container"
              onMouseEnter={toggleRight}
              onMouseLeave={toggleRight}
            >
              <div
                onClick={switchTestimonial}
                className={rotateRight ? "diamond" : "diamond rotate"}
                style={{
                  backgroundColor: "#97789b",
                  border: "0.8px solid black",
                  marginRight: width > 600 && "3rem",
                }}
              >
                <div className="arrow" style={{ border: "0.9px solid white" }}>
                  <div
                    className="arrow-before"
                    style={{
                      borderTop: "1px solid white",
                      borderRight: "1px solid white",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="testimonial-right"
          style={{ backgroundImage: `url(${IMAGES[currentCountryIndex]})` }}
        ></div>
        {/* <LazyImage
          imgOnly={false}
          styleish={{ height: "60vh" }}
          // src={IMAGES[currentCountryIndex]}
        // component={
        //   // <div
        //   //   className="testimonial-right"
        //   //   style={{ backgroundImage: `url(${IMAGES[currentCountryIndex]})`, width: "100%" }}
        //   // />
        // }
        /> */}

      </div>
      {/* </div> */}
    </>
  );
};
