import React, { useCallback, useEffect, useState } from "react";
import "../sass/layout/_signatureJourneys.scss";
import "../sass/main.scss";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import LazyImage from "./LazyImage.js";


export const SecondCard = ({ dest, shouldHighlight, index, setHighlightedIndex, img, navTo }) => {
    const navigate = useNavigate();
    const [modalWidth, setModalWidth] = useState("40vw");

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width > 900 && width <= 1200) {
            setModalWidth("40vw");
        }
        else if (width > 600 && width <= 900) {
            setModalWidth("50vw")
        } else if (width <= 600) {
            setModalWidth("100vw")
        }

    }, [width]);

    console.log("Moal : ", modalWidth)
    const toggleHighlight = (index) => {
        setHighlightedIndex(index);
    };

    const resetHighlight = () => {
        setHighlightedIndex(null);
    };

    const [openModal, setOpenModal] = useState(false);

    const onOpen = useCallback(() => setOpenModal(true), []);
    const onClose = useCallback(() => setOpenModal(false), []);

    const onClick = useCallback(() => navTo ? navigate(navTo) : null, [navTo, navigate]);


    return (
        <div key={index} className="jr-card" onMouseEnter={() => toggleHighlight(index)} onMouseLeave={resetHighlight}>
            <LazyImage
                component={
                    <div className="jr-card-upper" style={{ backgroundImage: `url(${img})`, backgroundSize: "cover", }}>
                        <button className="button-primary" onClick={onOpen}>Quick View</button>
                        <Modal classNames={{
                            // modal: "modal"
                        }} closeOnEsc styles={{
                            modal: {
                                borderRadius: "0.5rem",
                                // height: "40vh",
                                // height: "0vh",
                                alignSelf: "center",
                                width: modalWidth,
                                backgroundColor: "#97b2b7",
                                position: "absolute",
                                alignContent: "center",
                                top: "30%",
                                right: "30%",
                                transform: "translate(-50%,-50%)",
                            }

                        }} open={openModal} onClose={onClose}>
                            <div className="modal">
                                <div className="modal-left" style={{ backgroundImage: `url(${img})` }} />
                                <div className="modal-right">
                                    {/* <h2 className="jr-card-lower-heading">{dest.destination}</h2> */}
                                    <h3 className="jr-card-lower-heading">{dest.description} {dest.sub_description}</h3>
                                    <div className="modal-right-description">{Array.isArray(dest.attractions) ?
                                        dest.attractions.map(attr => {
                                            return (
                                                <ul>
                                                    <h1>{attr.dest}</h1>
                                                    <li>{attr.descrip}</li>
                                                </ul>
                                            )
                                        })
                                        :
                                        dest.attractions
                                    }</div>
                                </div>
                            </div>
                            <button style={{ marginTop: "1rem" }} className="button-pinkish">Enquire Now</button>
                        </Modal>
                    </div>

                }
            />
            <div onClick={onClick} className="jr-card-lower">
                <h3 className="jr-card-lower-heading">{dest.destination}</h3>
                <p className="jr-card-lower-heading-sub">{dest.description}</p>
                <p className="jr-card-lower-heading-description">{dest.sub_description}</p>
                <div onClick={onClick} className="arrow-container" style={{ marginTop: "2rem" }}>
                    <div key={index} className="diamond" style={{ backgroundColor: "transparent", border: "0.7px solid white" }}>
                        <div className="arrow" style={{ backgroundColor: "white" }}>
                            <div className="arrow-before" style={{
                                "borderTop": "0.7px solid white",
                                "borderRight": "0.7px solid white"
                            }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export const Card = ({ data, icon, images }) => {

//     return data.map(dataPoint => {
//         if (dataPoint.tours) {
//             return dataPoint.tours.map(journey => {
//                 return (
//                     <div className="jr-card">
//                         <div className="jr-card-upper">
//                             <img src={images[journey.img_filename]} className="jr-card-image" alt="/" />
//                         </div>
//                         <div className="jr-card-lower">
//                             <h3 className="jr-card-lower-heading">{journey.destination}</h3>
//                             <p className="jr-card-lower-heading-sub">{journey.description}</p>
//                             <p className="jr-card-lower-heading-description">{journey.sub_description}</p>
//                             <img src={icon} alt="" className="jr-card-lower-arrow" />
//                         </div>
//                     </div>
//                 )
//             })
//         }
//         else {
//             <div className="jr-card">
//                 <div className="jr-card-upper">
//                     <img src={images[dataPoint.img_filename]} className="jr-card-image" alt="/" />
//                 </div>
//                 <div className="jr-card-lower">
//                     <h3 className="jr-card-lower-heading">{dataPoint.destination}</h3>
//                     <p className="jr-card-lower-heading-sub">{dataPoint.description}</p>
//                     <p className="jr-card-lower-heading-description">{dataPoint.sub_description}</p>
//                     <img src={icon} alt="" className="jr-card-lower-arrow" />
//                 </div>
//             </div>

//         }

//         return (
//             <div className="jr-card" style={{ height: "40vh" }}>
//                 <div className="jr-card-lower">
//                     <h2 style={{ fontSize: "1.5rem" }} className="jr-card-lower-heading">{dataPoint.value}</h2>
//                     <p style={{ fontSize: "1rem" }} className="jr-card-lower-heading-sub-about">{dataPoint.description}</p>
//                     <img src={icon[dataPoint.icon]} alt="" className="jr-card-lower-arrow" />
//                 </div>
//             </div>
//         )


//     })
// }